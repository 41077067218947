.news__container {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 45px;
}

.linkedpage {
  background: $persian-green;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -0.025rem;
  padding: 0.1em 0.5em;

  &.linkedpage--highlighted {
    background: $white;
    color: $persian-green;
    padding-left: 0;
    padding-right: 0;
    font-size: 1.0em;

    @include media-breakpoint-only(sm) {
      margin-top: -0.4em;
    }

    @include media-breakpoint-up(md) {
      font-size: 0.95em;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.1em;
    }
  }

  .linked__link {
    color: $white;
  }
}

.stream__content--year-hint {
  margin-top: 1em;
  margin-bottom: 0.2em;
}

.stream-item {
  background: $monte-carlo;
  padding: 0.5em 1em;
  margin-bottom: 1em;
}

.stream-item__title {
  @extend .heading__title;
  line-height: 1.1em;
  font-size: 1.4em;

  @include media-breakpoint-up(sm) {
    font-size: 1.5em;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.6em;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.6em;
  }

  @include media-breakpoint-up(xxl) {
    line-height: 1.33rem;
    font-size: 1.5rem;
    letter-spacing: -0.025rem;
  }
}

.stream-item__title--linked {
  color: $main-gray;
}

.stream-item__date-category {
  color: $blue;
  text-transform: uppercase;
  font-size: 1.1em;
  line-height: 0.9;
}

.stream-item__content {
  line-height: 1.1rem;
  font-size: 0.85rem;
  color: $main-gray;

  @include media-breakpoint-up(md) {
    font-size: 0.9rem;
    line-height: 1.15rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: .95rem;
    line-height: 1.2rem;
  }

  &.stream-item__content--highlighted {
    font-size: 0.95rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.05rem;
    }
  }
}

.stream-filter__option-hint {
  color: $gray-400;
}

.teaser__content {
  padding-top: 0.5em;

  @include media-breakpoint-up(sm) {
    padding-top: 0;
  }

  @include media-breakpoint-up(md) {
    padding-top: 1.2em;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 2em;
  }
}


// slider settings
.stream__content--slider {
  .item:nth-child(n + 2) {
    display: none;
  }
}

.streamteaser {
  display: flex;
  // flex-wrap: nowrap;
  overflow-x: scroll;
  overflow: hidden;
  position: relative;

  .swiper-wrapper {
    display: flex;
    width: 100%;
  }
}


.stream-nav {
  display: flex;
  font-size: 2.4em;
  font-weight: 600;
  line-height: 1em;
  vertical-align: middle;
  color: $gray-500;
}

.stream-nav__item {
  color: $gray-500;
  padding: 0.05em 0.45em 0.15em;

  &:hover {
    text-decoration: none;
    background: $gray-100;
  }

  @include media-breakpoint-up(md) {
    padding: 0.05em 1em 0.15em;
  }
}

.stream-nav__item--active {
  color: $blue;
}

.stream-nav__track {
  display: flex;
  flex: 1;
}

.stream-nav__button {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#{$blue}" d="M6 0l12 12-12 12z"/></svg>') no-repeat;
  background-size: cover;
  width: 1.1em;
  height: 1.1em;

  background-position-x: 0.27em;
}

.stream-nav__button--prev {
  transform: scaleX(-1);
}

.stream-nav__button--next {
  align-self: flex-end;
}

.stream-item__readon {
  margin-top: auto;
  padding-bottom: 0.5em;
  font-size: 1.0em;
  text-transform: uppercase;
  font-weight: 600;
}

.stream-filter {
  margin-bottom: 1em;
}

.stream-filter__label {
  text-transform: uppercase;
  text-align: right;
  color: $blue;
}
