.home__img {
  height: 100%;
  max-height: 66vh;
  position: relative;
  width: 100%;

}

.home__map {
  width: 100%;
  height: 80vh;

  @include media-breakpoint-up(sm) {
    width: calc(100%);
    height: 66vh;
  }
}

.heading--home {
  margin-bottom: 1em;

  @include media-breakpoint-up(sm) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.homenews {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow: hidden;
  margin-bottom: 2em;
  position: relative;

  @include media-breakpoint-down(sm) {
    margin-top: 1em;
  }

  padding-left: 10px;
  padding-right: 10px;

  @include media-breakpoint-up(md) {
    padding-left: 30px;
    padding-right: 30px;
  }

  .swiper-wrapper {
    display: flex;
    width: 100%;
  }
}

.homenews__entry {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

.homenews__item {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: transparentize($persian-green, 1 - 0.23);
  font-size: 0.8em;
  padding: 1.5em;
  padding-bottom: 1em;
  margin-bottom: 0;
}

.homenews__arrow {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
  position: absolute;
  top: 0;
  width: 12vw;
  height: 100%;
  right: 0;
  z-index: 2;
  cursor: pointer;

  @include media-breakpoint-down(sm) {
    width: 2em;
  }

  @include media-breakpoint-up(lg) {
    width: 7vw;
  }

  &:hover {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);
  }
&:after {
  content: "";
  width: 2em;
  height: 4em;
  background: url("data:image/svg+xml,%3Csvg viewBox='0 0 37 88' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='1.414'%3E%3Cpath d='M36.013 46.532L10.287 87.889l-7.642-4.754 22.8-36.654L0 4.68 7.688 0l28.325 46.532z' fill='%23d3d3d3' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right;
  top: calc(50% - 2em);
  right: 1.2em;
  position: absolute;
  @include media-breakpoint-down(sm) {
    right: 0.4em;
    top: calc(50% - 1.5em);
    width: 1.5em;
    height: 3em;
  }
}

&:hover:after {
  background-image:url("data:image/svg+xml,%3Csvg viewBox='0 0 37 88' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='1.414'%3E%3Cpath d='M36.013 46.532L10.287 87.889l-7.642-4.754 22.8-36.654L0 4.68 7.688 0l28.325 46.532z' fill='#{$blue}' fill-rule='nonzero'/%3E%3C/svg%3E");
}
}

.homenews__arrow--prev {
  transform: scaleX(-1);
  left: 0;
}

.homenews__arrow--disabled {
  display: none;
}
