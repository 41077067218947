.infobox {
  background: transparentize($persian-green, 1 - 0.23);
  padding: 1em;
  margin-bottom: 2em;

  font-size: 1em;
  @include media-breakpoint-up(lg) {
    font-size: 1.1em;
  }
}

.infobox__title {
  font-size: 1.2em;
}
