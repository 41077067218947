$gallery-background: transparentize($persian-green, 0.23);

.gallery-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  .img-fluid {
    width: 100%;
  }

  .gallery__enlarge {
    @extend .oi-fullscreen-enter;
    @extend .oi;
    top: 0px;
    width: 100%;

    &:before {
      background: $gallery-background;
      color: $white;
      font-family: 'Icons';
      font-size: 1.6em;
      line-height: 1.8em;
      padding: 0 0.4em;
      position: absolute;
      right: 1em;
      text-align: center;
      top: 0.75em;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }

  @include media-breakpoint-up(md) {
    &:hover {
      .gallery__enlarge:before,
      .caption {
        visibility: visible;
        opacity: 1.0;
        background: $gallery-background;
      }
    }
  }
}

.caption {

  @include media-breakpoint-up(md) {
    font-size: 0.9em;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.0em;
  }

  &.caption--embed {
    background: $gallery-background;
    bottom: 0;
    color: $white;
    cursor: auto;
    height: auto;
    padding: 10px;
    width: 100%;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.35);
  }

  .caption__author {
    text-align: right;
  }
}

.gallery__enlarge:before,
.caption {
  background: transparent;
  opacity: 0;
  transition: visibility 0.5s linear 0s, opacity .15s linear, background .15s linear;
  position: absolute;
  visibility: hidden;
  z-index: 1;
}
