.heading {

}

.heading__title {
  text-transform: uppercase;
  font-weight: 900;
  color: $main-gray;
  font-size: 26px;
  line-height: 1.1;

  @include media-breakpoint-up(sm) {
    font-size: 32px;
  }

  @include media-breakpoint-up(md) {
    font-size: 38px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 46px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 52px;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 60px;
  }

  &.heading__title--pushed-up {
    margin-top: -0.15em;
    margin-bottom: 0.3em;
  }

  &.heading__title--closer {
    line-height: 0.9em;
  }

  &.heading__title--special {
    font-size: 1.9em;
    line-height: 1.0em;

    @include media-breakpoint-up(sm) {
      font-size: 2.1em;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.6em;
    }

    @include media-breakpoint-up(xl) {
      font-size: 3.2em;
      line-height: 0.9em;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 3.6em;
    }

    small {
      font-size: 48%;
      line-height: 1em;
      margin-top: 0.25em;
      display: block;
    }
  }

  .heading__info--spaced {
    line-height: 1.25em;
  }
}

.heading__info {
  line-height: 1.1;
  padding-top: 0.33em;
  margin-left: auto;
  color: $main-gray;

  @include media-breakpoint-up(sm) {
    font-size: 1.0rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 1.0rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.1rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.25rem;
  }
}
