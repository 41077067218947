.marker {
  max-width: 20em;
}

.marker__city {
  color: $main-gray;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.2em;
}

.marker__title {
  font-weight: bold;
  font-size: 0.9em;
}

.map {
  height: 66vh;

  @include media-breakpoint-down(md) {
    height: 85vh;
  }

  @include media-breakpoint-up(xl) {
    width: calc(100% - 200px);
    margin-left: 30px;
  }
}

.map-body {
  @include media-breakpoint-up(xl) {
    padding-left: 30px;
    padding-right: 200px;
  }
}

.heading.heading--map {
  padding-bottom: 15px;

  @include media-breakpoint-up(xl) {
    padding-left: 30px;
    padding-right: 200px;
  }
}

.memberlist {
  padding-top: 1em;
  padding-bottom: 1em;

  @include media-breakpoint-down(lg) {
    display: flex;
    margin-left: -0.5em;
    margin-right: -0.5em;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @include media-breakpoint-up(lg) {
    columns: 2;
  }
}

.memberitem__container {
  border-bottom: $persian-green 1rem solid;
  margin-bottom: 1.5rem;
}

.memberitem {
  display: flex;
  break-inside: avoid-column;
  page-break-inside: avoid;
  padding: 1rem;
  border: 2px dotted $gray-300;
  border-bottom: none;

  @include media-breakpoint-down(lg) {
    width: 50%;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
  }

}

.memberitem__image {
  width: 33%;
  height: 100%;
  margin-right: 0.5em;
}

.memberitem__info {
  width: 67%;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.memberitem__address {
  text-transform: uppercase;
  font-size: .9rem;
  line-height: 1.1rem;
  font-style: normal;
  font-weight: semibold;
}

.memberitem__title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.0rem;
  line-height: 1.1;
  margin-bottom: 0.5rem;
}


.memberlist__title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 1.0rem;
  margin-bottom: 0;
  letter-spacing: -0.025rem;
}

.text-scarlet-gum {
  color: $scarlet-gum;
}

.text-persian-green {
  color: $persian-green;

  &:hover {
    color: lighten($persian-green, 10%);
  }

  &:active {
    color: darken($persian-green, 10%);
  }
}

