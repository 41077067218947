.member {
  height: 100%;

  @include media-breakpoint-up(lg) {
    padding-left: 15px;
  }

  @include media-breakpoint-up(xl) {
    padding-left: 40px;
    padding-right: 15px;
  }
}

.member__title {
  padding-bottom: 1em;
}

.member__body {
  line-height: 1.4;
  font-weight: 300;
  color: $black;
}

.member__image--small {
  padding-bottom: 1em;
}

.member__image--large {
  padding-right: 0;
}

.contact {
  background: transparentize($persian-green, 1 - 0.23);
  padding-bottom: 1em;
  margin-top: auto;
  font-size: 0.9em;

  @include media-breakpoint-up(sm) {
    font-size: 1.0em;
  }
}

.contact__title {
  background: $persian-green;
  text-transform: uppercase;
  color: $white;
  font-size: 1.1em; //17px
  font-weight: 900;
  padding: 0.2em;
}

.contact__city,
.contact__fonfax,
.contact__street,
.contact__web {
  padding: 1em 1em 0;
}

.contact__street {
  padding-top: 0.5em;
}

.contact__city {
  padding-top: 0;
}

.contact__web {
  color: $persian-green;
  text-transform: uppercase;
}

.member__body {
  ul {
    margin-left: 0;
    padding-left: 0;

    li {
      list-style: none;
      margin-bottom: 0.5em;
      line-height: 1.2em;

      b {
        color: darken($blue, 5%);
        text-transform: uppercase;
      }
    }
  }
}
