// White links against a dark background
.navbar-tzs {
  align-self: start;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;

  @include media-breakpoint-down(md) {
    align-items: end;

    .navbar-collapse {
      width: 100%;
    }
  }

  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  @include media-breakpoint-up(lg) {
    align-items: end;
  }

  .navbar-brand {
    color: $navbar-dark-active-color;
    align-items: center;
    align-self: flex-start;
    display: flex;

    @include media-breakpoint-down(sm) {
      width: 100%;
      justify-content: center;
      margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 30px;
    }

    .navbar-brand-logo {
      width: auto;
      max-width: 50%;
      padding: 0.5rem;
      height: 100%;

      @include media-breakpoint-up(sm) {
        height: 80px;
        padding: 0;
        max-width: 100%;
      }

      @include media-breakpoint-up(lg) {
        height: 100px;
        margin-top: 0px;
      }

      @include media-breakpoint-up(xl) {
        height: 140px;
        margin-top: 0px;
      }
    }

    @include hover-focus {
      color: $navbar-dark-active-color;
    }
  }


  .navbar-nav {
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 1em;
      margin-top: 1em;
    }

    .nav-link {
      color: $white;
      font-size: 1.25rem;
      padding: 0.25rem 1.5rem;
      font-weight: 900;

      @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.5rem;
      }

      @include hover-focus {
        color: $navbar-dark-hover-color;
      }
      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }
    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $white;
      text-decoration: underline;
      background-color: $primary-dark;
    }
  }
  .navbar-toggler {
    color: $navbar-dark-color;
    border-color: $navbar-dark-toggler-border-color;
  }
  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }
}
