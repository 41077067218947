#cookies-eu-banner {
  background: #444;
  color: #fff;
  padding: 6px;
  font-size: 13px;
  text-align: center;
}

#cookies-eu-more,
#cookies-eu-accept,
#cookies-eu-reject {
  text-decoration: none;
  background: #222;
  color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  padding: 4px 7px;
  margin: 2px 0;
  font-size: 13px;
  font-weight: bold;
  transition: background 0.07s, color 0.07s, border-color 0.07s;
}

#cookies-eu-more:hover,
#cookies-eu-more:focus,
#cookies-eu-accept:hover,
#cookies-eu-accept:focus {
  background: #fff;
  color: #222;
}

#cookies-eu-more {
  margin-left: 7px;
}

#cookies-eu-reject {
  background: none;
  font-weight: normal;
  color: #ccc;
  cursor: pointer;
  padding: 4px 7px;
  margin: 2px 0 2px 10px;
  border: 1px solid #666;
}

#cookies-eu-reject:hover,
#cookies-eu-reject:focus {
  border-color: #fff;
  background: #222;
  color: #fff;
}
