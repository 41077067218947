.content {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(sm) {
    padding-right: 30px;
  }
  @include media-breakpoint-up(lg) {
    padding-right: 90px;
  }
}

.content__body {
  text-align: justify;
  color: $black;
  line-height: 1.2em;
  font-weight: 300;
  hyphens: auto;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: left;
  }
  a {
    word-wrap: break-word;
  }

  ul {
    margin-top: -0.5em;
    text-align: left;
    & > li {
      margin-bottom: 0.1em;
    }
  }

  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 18px;
  }

  .block-document {
    text-align: left;
    margin-bottom: 0.75em;
  }

  .block-document__filename {
    font-size: 0.9em;
    color: $black;
  }

}


.content__body--columns {
  height: 100%;
  @include media-breakpoint-up(xl) {
    columns: 2;
    column-fill: balance;
  }
}

.block-intro {
  font-weight: 600;
}

.go-back {
  text-transform: uppercase;
  font-weight: 400;
}

.metadata {
  margin: 0.2em 0 0.6em;
}

.metadata__date-category {
  color: $blue;
  text-transform: uppercase;
  font-size: 1.1em;
  line-height: 0.9;
}

.content_gallery {
  margin-bottom: 2em;
}

.content-link {
  line-height: 1.1em;
  margin-bottom: 0.25em;
}
