@import "./styles/custom.scss";

$icon-font-path: "../node_modules/open-iconic/font/fonts/";
@import "node_modules/open-iconic/font/css/open-iconic-bootstrap.scss";
@import "node_modules/bootstrap/scss/bootstrap";

@import "node_modules/select2-bootstrap4-theme/src/select2-bootstrap4.scss";
@import "./styles/swiper/pagination.scss";

@import "./styles/slider.scss";
@import "./styles/content.scss";
@import "./styles/heading.scss";
@import "./styles/navbar.scss";
@import "./styles/members.scss";
@import "./styles/map.scss";
@import "./styles/home.scss";
@import "./styles/static.scss";
@import "./styles/news.scss";
@import "./styles/gallery.scss";
@import "./styles/cookiebanner.scss";

.page-padding {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 3.5rem;
    padding-bottom: 30px;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 5rem;
    padding-bottom: 30px;
  }

  &.page-padding--no-top {
    @include media-breakpoint-up(lg) {
      padding-top: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 3rem;
    }
  }
}

.img--full {
  height: auto;
  width: 100%;
}

body {
  overflow-y: scroll;
}
